import config from '../config.json';

const getConfigValue = (name: string) => {
  const w = window as any;
  const c = config as any;
  return (w._env && w._env[name]) || c[name]; 
}

export const _config = Object.keys(config).reduce((obj: any, key: any) => {
  obj[key] = getConfigValue(key);
  return obj;
}, {});

export const UTC_API_URL = getConfigValue('UTC_API_URL');
export const UTC_API_AUDIENCE = getConfigValue('UTC_API_AUDIENCE');
export const UTC_ADMIN_CLIENT_ID = getConfigValue('UTC_ADMIN_CLIENT_ID');
export const UTC_OIDC_DOMAIN = getConfigValue('UTC_OIDC_DOMAIN');
export const UTC_ADMIN_AUTH_DISABLED = getConfigValue('UTC_ADMIN_AUTH_DISABLED');
