import { UseQueryOptions } from "@tanstack/react-query";
import { UtcApiClient } from "../Api/UtcApiClient";
import { ConsultationSubmission, ConsultationSubmissionStatus, ConsultationSubmissionsApi, User, UsersApi } from "../Api/utc-api";
import { ConsultationSubmissionExt } from "../Types/ConsultationSubmissionExt";

const getConsultationSubmissions = async (centreId?: string, filterStatuses?: ConsultationSubmissionStatus[]):
  Promise<ConsultationSubmission[]> => {
  const consultationSubmissionsApi = await UtcApiClient.getResourceClient(ConsultationSubmissionsApi);
  return await consultationSubmissionsApi.getConsultationSubmissions({ centreId, status: filterStatuses });
};

const getUsers = async (): Promise<User[]> => {
  const usersApi = await UtcApiClient.getResourceClient(UsersApi);
  return await usersApi.getUsers();
};

export const getAllConsultationSubmissions = async (centreId?: string, filterStatuses?: ConsultationSubmissionStatus[]): Promise<ConsultationSubmissionExt[]> => {
  const [submissions, users] = await Promise.all([getConsultationSubmissions(centreId, filterStatuses), getUsers()]);

  const data = submissions.map(submission => {
    const user = users?.find(_ => _.id === submission.userId);
    const submitter: string = user ? [user.title, user.firstName, user.lastName].filter(_ => _).join(' ') : '';
    return { ...submission, submitter };
  });

  return data;
};

export const ConsultationSubmissionsQuery: UseQueryOptions<ConsultationSubmissionExt[]> = {
  queryKey: ['allConsultationSubmissions'],
  queryFn: () => getAllConsultationSubmissions(),
  refetchInterval: 10000,
  refetchOnWindowFocus: false
};