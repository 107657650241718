import { Address } from "../Api/utc-api";

export const constantsToArray = (object: any) =>
  Object.keys(object).reduce((a: string[], c: string) => [...a, object[c]], []);

export const handleElementChange = <T>(e: React.ChangeEvent<HTMLInputElement>, setFn: React.Dispatch<React.SetStateAction<T>>) => {
  const parent = e.target.getAttribute('parent-name');
  const name = e.target.name;
  let value: any = e.target.value;

  const inputMode = e.target.getAttribute('inputMode');
  if (inputMode === 'numeric')
    value = parseFloat(value);

  setFn(e => {
    const updated: any = { ...e };
    if (parent) {
      if (!updated[parent])
        updated[parent] = {};
      updated[parent][name] = value;
    }
    else
      updated[name] = value;
    return updated;
  });
};



export const renderAddress = (address: Address | undefined) =>
  !address
    ? ''
    : [
      address.addressLine1,
      address.addressLine2,
      address.city,
      address.postcode
    ]
      .filter(_ => _)
      .join('<br />');