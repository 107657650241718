import { useState } from "react";
import { CentreInput, CentresApi, StaffApi } from "../../Api/utc-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleApiError } from "../../Api/http";
import { UtcApiClient } from "../../Api/UtcApiClient";
import { useNavigate, useParams } from "react-router-dom";
import CentreDetails from "../../Components/CentreDetails";
import StaffTable from "../../Components/StaffTable";

export default function ManageCentreView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { data: centre } = useQuery({
    queryKey: ['centres', { id }],
    queryFn: async () => {
      try {
        const centresApi = await UtcApiClient.getResourceClient(CentresApi);
        return await centresApi.getCentre({ id: id as string });
      } catch (e) {
        handleApiError(e);
        throw e;
      }
    },
    enabled: !!id,
    refetchOnWindowFocus: false
  });

  const { mutate: updateCentre } = useMutation({
    mutationFn: async (editedCentre: CentreInput) => {
      if (!id)
        throw new Error("Invalid data");

      const centresApi = await UtcApiClient.getResourceClient(CentresApi);
      await centresApi.updateCentre({ id, centreInput: editedCentre });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['centres']);
    },
    onError: () => {
      alert("An error occurred while updating centre");
    },
    onSettled: () => {
      setIsEditing(false);
    }
  });

  const { mutate: deleteCentre } = useMutation({
    mutationFn: async () => {
      if (!id)
        throw new Error("Invalid data");

      const centresApi = await UtcApiClient.getResourceClient(CentresApi);
      await centresApi.deleteCentre({ id });
    },
    onSuccess: () => {
      navigate('/admin/manage-centres');
      setTimeout(() =>  queryClient.invalidateQueries(['centres']), 500);
    },
    onError: () => {
      alert("An error occurred while deleting centre");
    }
  });

  const { data: staff } = useQuery({
    queryKey: ['staff', { centreId: id }],
    queryFn: async () => {
      try {
        const staffApi = await UtcApiClient.getResourceClient(StaffApi);
        return await staffApi.getStaff({ centreId: [id as string] });
      } catch (e) {
        handleApiError(e);
        throw e;
      }
    },
    enabled: !!id,
    refetchOnWindowFocus: false
  });

  if (!centre)
    return <>Loading...</>;

  return <>
    <h1 className="title is-2">Manage Centre</h1>

    <div className="section-container">
      <CentreDetails centre={centre} isEditing={isEditing} onSave={editedCentre => updateCentre(editedCentre)} />
      {
        isEditing
          ? <p>
            <button className="button" onClick={() => setIsEditing(false)}>Cancel</button>
          </p>
          : <p>
            <button className="button" onClick={() => setIsEditing(true)}>Edit</button>
            <button className="button is-danger ml-2" onClick={() => {
              if (window.confirm("Are you sure you wish to delete this centre?"))
                deleteCentre();
            }}>Delete</button>
          </p>
      }
    </div>

    <div className="mt-4">
      <h4 className="title is-4">Assigned Staff</h4>
      {
        !staff
        ? <>Loading...</>
        : <StaffTable staff={staff} viewUrl="/admin/manage-staff" />
      }
    </div>
  </>;
};