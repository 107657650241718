import { useContext, useState } from "react";
import { StaffMemberInput, StaffApi } from "../../Api/utc-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleApiError } from "../../Api/http";
import { UtcApiClient } from "../../Api/UtcApiClient";
import { useNavigate, useParams } from "react-router-dom";
import StaffMemberDetails from "../../Components/StaffMemberDetails";
import CentresTable from "../../Components/CentresTable";
import { ApplicationContext } from "../../contexts/ApplicationContext";

export default function ManageStaffMemberView() {
  const { id } = useParams();
  const { centres } = useContext(ApplicationContext);
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { mutate: updateStaffMember } = useMutation({
    mutationFn: async (editedStaffMember: StaffMemberInput) => {
      if (!id)
        throw new Error("Invalid data");

      const staffApi = await UtcApiClient.getResourceClient(StaffApi);
      await staffApi.updateStaffMember({ id, staffMemberInput: editedStaffMember });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['staff']);
    },
    onError: () => {
      alert("An error occurred while updating staff member");
    },
    onSettled: () => {
      setIsEditing(false);
    }
  });

  const { mutate: deleteStaffMember } = useMutation({
    mutationFn: async () => {
      if (!id)
        throw new Error("Invalid data");

      const staffApi = await UtcApiClient.getResourceClient(StaffApi);
      await staffApi.deleteStaffMember({ id });
    },
    onSuccess: () => {
      navigate('/admin/manage-staff');
      setTimeout(() =>  queryClient.invalidateQueries(['staff']), 500);
    },
    onError: () => {
      alert("An error occurred while deleting staff member");
    }
  });

  const { data: staffMember } = useQuery({
    queryKey: ['staff', { staffMemberId: id }],
    queryFn: async () => {
      try {
        const staffApi = await UtcApiClient.getResourceClient(StaffApi);
        return await staffApi.getStaffMember({ id: id as string });
      } catch (e) {
        handleApiError(e);
        throw e;
      }
    },
    enabled: !!id,
    refetchOnWindowFocus: false
  });

  const assignedCentres = () => {
    if (!centres)
      return [];

    return centres.filter(_ => staffMember?.assignedCentres?.includes(_.id));
  }

  if (!staffMember || !centres)
    return <>Loading...</>;

  if (isEditing)
    return (<>
      <div className="section-container">
        <StaffMemberDetails staffMember={staffMember} isEditing={isEditing} onSave={editedStaffMember => updateStaffMember(editedStaffMember)} />
        <button className="button ml-2" onClick={() => setIsEditing(false)}>Cancel</button>

      </div>
    </>);

  return <>
    <h1 className="title is-2">Manage Staff Member</h1>

    <div className="section-container">
      <StaffMemberDetails staffMember={staffMember} isEditing={isEditing} onSave={editedStaffMember => updateStaffMember(editedStaffMember)} />
      <p>
        <button className="button" onClick={() => setIsEditing(true)}>Edit</button>
        <button className="button is-danger ml-2" onClick={() => {
          if (window.confirm("Are you sure you wish to delete this staff member?"))
            deleteStaffMember();
        }}>Delete</button>
      </p>
    </div>

    <div className="mt-4">
      <h4 className="title is-4">Assigned Centres</h4>
      {
        !staffMember
          ? <>Loading...</>
          : <CentresTable centres={assignedCentres()} viewUrl="/admin/manage-centres" />
      }
    </div>
  </>;
};