import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { UtcApiClient } from "../../Api/UtcApiClient";
import { NewUserInput, UsersApi } from "../../Api/utc-api";
import { useState } from "react";
import { UserRoles } from "../../constants/auth";
import UsersTable from "../../Components/UsersTable";
import UserDetails from "../../Components/UserDetails";

export default function ManageAdminsView() {
  const [showAddAdmin, setShowAddAdmin] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { data: admins } = useQuery({
    queryKey: ['users', { role: 'admin' }],
    queryFn: async () => {
      const usersApi = await UtcApiClient.getResourceClient(UsersApi);
      return await usersApi.getUsers({ role: [UserRoles.admin] });
    },
    refetchOnWindowFocus: false
  });

  const { mutate: addAdmin } = useMutation({
    mutationFn: async (newAdmin: NewUserInput) => {
      const usersApi = await UtcApiClient.getResourceClient(UsersApi);
      await usersApi.createUser({ newUserInput: { ...newAdmin, roles: [UserRoles.admin] } });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
      setShowAddAdmin(false);
    },
    onError: () => {
      alert("An error occurred while adding user");
    }
  });

  return <>
    <h1 className="title is-2">Manage Admins</h1>

    {
      showAddAdmin
        ? <>
          <UserDetails user={{ id: '', email: '', firstName: '', lastName: '' }} isEditing={true} onSave={newAdmin => addAdmin(newAdmin)} />
          <p>
            <button className="button" onClick={() => setShowAddAdmin(false)}>Cancel</button>
          </p>
        </>
        : !admins
          ? <>Loading...</>
          : <>
            <button className="button is-success mb-2" onClick={() => setShowAddAdmin(true)}>Add Admin</button>
            <UsersTable users={admins} viewUrl="/admin/manage-admins" />
          </>
    }
  </>;
};