import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  UTC_API_AUDIENCE,
  UTC_ADMIN_CLIENT_ID,
  UTC_OIDC_DOMAIN
} from "./config/app-config";
import { Auth0Provider } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router-dom';
import router from './Routing/Router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApplicationProvider } from './contexts/ApplicationContext';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={UTC_OIDC_DOMAIN}
      clientId={UTC_ADMIN_CLIENT_ID}
      cacheLocation='localstorage'
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: UTC_API_AUDIENCE
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ApplicationProvider>
          <RouterProvider router={router} />
        </ApplicationProvider>
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
