/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConsultationSubmissionComment
 */
export interface ConsultationSubmissionComment {
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionComment
     */
    comment: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConsultationSubmissionComment
     */
    isPublic: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionComment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionComment
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionComment
     */
    displayName: string;
    /**
     * 
     * @type {Date}
     * @memberof ConsultationSubmissionComment
     */
    dateCreated: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ConsultationSubmissionComment
     */
    systemGenerated: boolean;
}

/**
 * Check if a given object implements the ConsultationSubmissionComment interface.
 */
export function instanceOfConsultationSubmissionComment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "comment" in value;
    isInstance = isInstance && "isPublic" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "dateCreated" in value;
    isInstance = isInstance && "systemGenerated" in value;

    return isInstance;
}

export function ConsultationSubmissionCommentFromJSON(json: any): ConsultationSubmissionComment {
    return ConsultationSubmissionCommentFromJSONTyped(json, false);
}

export function ConsultationSubmissionCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationSubmissionComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': json['comment'],
        'isPublic': json['isPublic'],
        'id': json['id'],
        'userId': json['userId'],
        'displayName': json['displayName'],
        'dateCreated': (new Date(json['dateCreated'])),
        'systemGenerated': json['systemGenerated'],
    };
}

export function ConsultationSubmissionCommentToJSON(value?: ConsultationSubmissionComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
        'isPublic': value.isPublic,
        'id': value.id,
        'userId': value.userId,
        'displayName': value.displayName,
        'dateCreated': (value.dateCreated.toISOString()),
        'systemGenerated': value.systemGenerated,
    };
}

