/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { CentreStatus } from './CentreStatus';
import {
    CentreStatusFromJSON,
    CentreStatusFromJSONTyped,
    CentreStatusToJSON,
} from './CentreStatus';

/**
 * 
 * @export
 * @interface CentreInput
 */
export interface CentreInput {
    /**
     * 
     * @type {string}
     * @memberof CentreInput
     */
    name: string;
    /**
     * 
     * @type {Address}
     * @memberof CentreInput
     */
    address?: Address;
    /**
     * 
     * @type {CentreStatus}
     * @memberof CentreInput
     */
    status?: CentreStatus;
}

/**
 * Check if a given object implements the CentreInput interface.
 */
export function instanceOfCentreInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CentreInputFromJSON(json: any): CentreInput {
    return CentreInputFromJSONTyped(json, false);
}

export function CentreInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CentreInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'status': !exists(json, 'status') ? undefined : CentreStatusFromJSON(json['status']),
    };
}

export function CentreInputToJSON(value?: CentreInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': AddressToJSON(value.address),
        'status': CentreStatusToJSON(value.status),
    };
}

