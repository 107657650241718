import { Link } from "react-router-dom";
import { User } from "../Api/utc-api";

export default function UsersTable({ users, viewUrl }:
  { users: User[], viewUrl?: string }) {

  const getUserUrl = (user: User) => {
    if (!viewUrl) return '';
    return `${viewUrl}${!viewUrl.endsWith('/') ? '/' : ''}${user.id}`;
  };


  return (
    <table className="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          {!!viewUrl && <th></th>}
        </tr>
      </thead>
      <tbody>
        {
          !users
            ? <tr><td colSpan={6}>Loading...</td></tr>
            : !users.length
              ? <tr><td colSpan={6}>No users</td></tr>
              : users.map(user => (
                <tr key={user.id}>
                  <td>{user.title} {user.firstName} {user.lastName}</td>
                  {!!viewUrl && <td><Link to={getUserUrl(user)}>View</Link></td>}
                </tr>
              ))
        }
      </tbody>
    </table>
  );
};