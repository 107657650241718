import { UserRoles, authNamespace } from "../constants/auth";

const getAccess = () => {
  const accessKey = Object.keys(localStorage).find(_ => _.includes('auth0spajs') && !_.includes('user'));
  if (accessKey) {
    const accessValue = localStorage.getItem(accessKey);
    if (accessValue)
      return JSON.parse(accessValue)?.body;
  }

  return null;  
};

const getId = () => {
  const idKey = Object.keys(localStorage).find(_ => _.includes('auth0spajs') && _.includes('user'));
  if (idKey) {
    const idValue = localStorage.getItem(idKey);
    if (idValue)
      return JSON.parse(idValue)?.decodedToken;
  }

  return null;  
};

export const getAccessToken = () => getAccess()?.access_token;

export const getUser = () => getId()?.user;

export const getUserId = () => getUser()?.[`${authNamespace}/id`];

export const getUserEmail = () => getUser()?.[`${authNamespace}/email`];

export const getUserRoles = () => getUser()?.[`${authNamespace}/role`];

export const userHasRole = (role: string) => getUserRoles()?.includes(role);

export const userIsAdmin = () => userHasRole(UserRoles.admin);

export const userIsStaff = () => userHasRole(UserRoles.staff);

export const userIsPatient = () => userHasRole(UserRoles.patient);

export const getUserAssignedCentres = (): string[] => userHasRole(UserRoles.staff)
  ? getUser()?.[`${authNamespace}/centre`]
  : null;