/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConsultationSubmissionStatus = {
    Pending: 'pending',
    Prioritised: 'prioritised',
    InProgress: 'in_progress',
    Completed: 'completed'
} as const;
export type ConsultationSubmissionStatus = typeof ConsultationSubmissionStatus[keyof typeof ConsultationSubmissionStatus];


export function ConsultationSubmissionStatusFromJSON(json: any): ConsultationSubmissionStatus {
    return ConsultationSubmissionStatusFromJSONTyped(json, false);
}

export function ConsultationSubmissionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationSubmissionStatus {
    return json as ConsultationSubmissionStatus;
}

export function ConsultationSubmissionStatusToJSON(value?: ConsultationSubmissionStatus | null): any {
    return value as any;
}

