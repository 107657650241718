/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Centre,
  CentreInput,
  CentreStatus,
} from '../models';
import {
    CentreFromJSON,
    CentreToJSON,
    CentreInputFromJSON,
    CentreInputToJSON,
    CentreStatusFromJSON,
    CentreStatusToJSON,
} from '../models';

export interface AddCentreRequest {
    centreInput: CentreInput;
}

export interface DeleteCentreRequest {
    id: string;
}

export interface GetCentreRequest {
    id: string;
}

export interface UpdateCentreRequest {
    id: string;
    centreInput: CentreInput;
}

export interface UpdateCentreStatusRequest {
    id: string;
    centreStatus: CentreStatus;
}

/**
 * 
 */
export class CentresApi extends runtime.BaseAPI {

    /**
     * Add centre
     */
    async addCentreRaw(requestParameters: AddCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Centre>> {
        if (requestParameters.centreInput === null || requestParameters.centreInput === undefined) {
            throw new runtime.RequiredError('centreInput','Required parameter requestParameters.centreInput was null or undefined when calling addCentre.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/centres`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CentreInputToJSON(requestParameters.centreInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CentreFromJSON(jsonValue));
    }

    /**
     * Add centre
     */
    async addCentre(requestParameters: AddCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Centre> {
        const response = await this.addCentreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete centre
     */
    async deleteCentreRaw(requestParameters: DeleteCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCentre.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/centres/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete centre
     */
    async deleteCentre(requestParameters: DeleteCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCentreRaw(requestParameters, initOverrides);
    }

    /**
     * Get centre
     */
    async getCentreRaw(requestParameters: GetCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Centre>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCentre.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/centres/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CentreFromJSON(jsonValue));
    }

    /**
     * Get centre
     */
    async getCentre(requestParameters: GetCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Centre> {
        const response = await this.getCentreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get centres
     */
    async getCentresRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Centre>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/centres`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CentreFromJSON));
    }

    /**
     * Get centres
     */
    async getCentres(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Centre>> {
        const response = await this.getCentresRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update centre
     */
    async updateCentreRaw(requestParameters: UpdateCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCentre.');
        }

        if (requestParameters.centreInput === null || requestParameters.centreInput === undefined) {
            throw new runtime.RequiredError('centreInput','Required parameter requestParameters.centreInput was null or undefined when calling updateCentre.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/centres/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CentreInputToJSON(requestParameters.centreInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update centre
     */
    async updateCentre(requestParameters: UpdateCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCentreRaw(requestParameters, initOverrides);
    }

    /**
     * Update centre status
     */
    async updateCentreStatusRaw(requestParameters: UpdateCentreStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCentreStatus.');
        }

        if (requestParameters.centreStatus === null || requestParameters.centreStatus === undefined) {
            throw new runtime.RequiredError('centreStatus','Required parameter requestParameters.centreStatus was null or undefined when calling updateCentreStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/centres/{id}/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CentreStatusToJSON(requestParameters.centreStatus),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update centre status
     */
    async updateCentreStatus(requestParameters: UpdateCentreStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCentreStatusRaw(requestParameters, initOverrides);
    }

}
