/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConsultationSubmission,
  ConsultationSubmissionComment,
  ConsultationSubmissionCommentInput,
  ConsultationSubmissionInput,
  ConsultationSubmissionStatus,
  ConsultationSubmissionStatusUpdate,
} from '../models';
import {
    ConsultationSubmissionFromJSON,
    ConsultationSubmissionToJSON,
    ConsultationSubmissionCommentFromJSON,
    ConsultationSubmissionCommentToJSON,
    ConsultationSubmissionCommentInputFromJSON,
    ConsultationSubmissionCommentInputToJSON,
    ConsultationSubmissionInputFromJSON,
    ConsultationSubmissionInputToJSON,
    ConsultationSubmissionStatusFromJSON,
    ConsultationSubmissionStatusToJSON,
    ConsultationSubmissionStatusUpdateFromJSON,
    ConsultationSubmissionStatusUpdateToJSON,
} from '../models';

export interface AddConsultationSubmissionCommentRequest {
    id: string;
    consultationSubmissionCommentInput: ConsultationSubmissionCommentInput;
}

export interface DeleteConsultationSubmissionRequest {
    id: string;
}

export interface GetConsultationSubmissionRequest {
    id: string;
}

export interface GetConsultationSubmissionsRequest {
    centreId?: string;
    status?: Array<ConsultationSubmissionStatus>;
}

export interface SubmitConsultationRequest {
    consultationSubmissionInput: ConsultationSubmissionInput;
}

export interface UpdateConsultationSubmissionRequest {
    id: string;
    consultationSubmissionInput: ConsultationSubmissionInput;
}

export interface UpdateConsultationSubmissionStatusRequest {
    id: string;
    consultationSubmissionStatusUpdate: ConsultationSubmissionStatusUpdate;
}

/**
 * 
 */
export class ConsultationSubmissionsApi extends runtime.BaseAPI {

    /**
     * Add a consultation submission comment
     */
    async addConsultationSubmissionCommentRaw(requestParameters: AddConsultationSubmissionCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsultationSubmissionComment>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addConsultationSubmissionComment.');
        }

        if (requestParameters.consultationSubmissionCommentInput === null || requestParameters.consultationSubmissionCommentInput === undefined) {
            throw new runtime.RequiredError('consultationSubmissionCommentInput','Required parameter requestParameters.consultationSubmissionCommentInput was null or undefined when calling addConsultationSubmissionComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-submissions/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConsultationSubmissionCommentInputToJSON(requestParameters.consultationSubmissionCommentInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsultationSubmissionCommentFromJSON(jsonValue));
    }

    /**
     * Add a consultation submission comment
     */
    async addConsultationSubmissionComment(requestParameters: AddConsultationSubmissionCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsultationSubmissionComment> {
        const response = await this.addConsultationSubmissionCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete consultation submission
     */
    async deleteConsultationSubmissionRaw(requestParameters: DeleteConsultationSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteConsultationSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-submissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete consultation submission
     */
    async deleteConsultationSubmission(requestParameters: DeleteConsultationSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteConsultationSubmissionRaw(requestParameters, initOverrides);
    }

    /**
     * Get consultation submission
     */
    async getConsultationSubmissionRaw(requestParameters: GetConsultationSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsultationSubmission>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getConsultationSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-submissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsultationSubmissionFromJSON(jsonValue));
    }

    /**
     * Get consultation submission
     */
    async getConsultationSubmission(requestParameters: GetConsultationSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsultationSubmission> {
        const response = await this.getConsultationSubmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get consultation submissions
     */
    async getConsultationSubmissionsRaw(requestParameters: GetConsultationSubmissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConsultationSubmission>>> {
        const queryParameters: any = {};

        if (requestParameters.centreId !== undefined) {
            queryParameters['centreId'] = requestParameters.centreId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-submissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConsultationSubmissionFromJSON));
    }

    /**
     * Get consultation submissions
     */
    async getConsultationSubmissions(requestParameters: GetConsultationSubmissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConsultationSubmission>> {
        const response = await this.getConsultationSubmissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit consultation
     */
    async submitConsultationRaw(requestParameters: SubmitConsultationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsultationSubmission>> {
        if (requestParameters.consultationSubmissionInput === null || requestParameters.consultationSubmissionInput === undefined) {
            throw new runtime.RequiredError('consultationSubmissionInput','Required parameter requestParameters.consultationSubmissionInput was null or undefined when calling submitConsultation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-submissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConsultationSubmissionInputToJSON(requestParameters.consultationSubmissionInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsultationSubmissionFromJSON(jsonValue));
    }

    /**
     * Submit consultation
     */
    async submitConsultation(requestParameters: SubmitConsultationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsultationSubmission> {
        const response = await this.submitConsultationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update consultation submission
     */
    async updateConsultationSubmissionRaw(requestParameters: UpdateConsultationSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateConsultationSubmission.');
        }

        if (requestParameters.consultationSubmissionInput === null || requestParameters.consultationSubmissionInput === undefined) {
            throw new runtime.RequiredError('consultationSubmissionInput','Required parameter requestParameters.consultationSubmissionInput was null or undefined when calling updateConsultationSubmission.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-submissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConsultationSubmissionInputToJSON(requestParameters.consultationSubmissionInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update consultation submission
     */
    async updateConsultationSubmission(requestParameters: UpdateConsultationSubmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateConsultationSubmissionRaw(requestParameters, initOverrides);
    }

    /**
     * Update consultation submission status
     */
    async updateConsultationSubmissionStatusRaw(requestParameters: UpdateConsultationSubmissionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateConsultationSubmissionStatus.');
        }

        if (requestParameters.consultationSubmissionStatusUpdate === null || requestParameters.consultationSubmissionStatusUpdate === undefined) {
            throw new runtime.RequiredError('consultationSubmissionStatusUpdate','Required parameter requestParameters.consultationSubmissionStatusUpdate was null or undefined when calling updateConsultationSubmissionStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/consultation-submissions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ConsultationSubmissionStatusUpdateToJSON(requestParameters.consultationSubmissionStatusUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update consultation submission status
     */
    async updateConsultationSubmissionStatus(requestParameters: UpdateConsultationSubmissionStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateConsultationSubmissionStatusRaw(requestParameters, initOverrides);
    }

}
