import { CentreInput, CentresApi } from "../../Api/utc-api";
import CentresTable from "../../Components/CentresTable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UtcApiClient } from "../../Api/UtcApiClient";
import { useContext, useState } from "react";
import CentreDetails from "../../Components/CentreDetails";
import { ApplicationContext } from "../../contexts/ApplicationContext";

export default function ManageCentresView() {
  const { centres } = useContext(ApplicationContext);
  const [showAddCentre, setShowAddCentre] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { mutate: addCentre } = useMutation({
    mutationFn: async (newCentre: CentreInput) => {
      const centresApi = await UtcApiClient.getResourceClient(CentresApi);
      await centresApi.addCentre({ centreInput: newCentre });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['centres']);
      setShowAddCentre(false);
    },
    onError: () => {
      alert("An error occurred while adding centre");
    }
  });

  return <>
    <h1 className="title is-2">Manage Centres</h1>

    {
      showAddCentre
        ? <>
          <CentreDetails centre={{ name: '' }} isEditing={true} onSave={newCentre => addCentre(newCentre)} />
          <p>
            <button className="button" onClick={() => setShowAddCentre(false)}>Cancel</button>
          </p>
        </>
        : !centres
          ? "Loading..."
          : <>
            <button className="button is-success mb-2" onClick={() => setShowAddCentre(true)}>Add Centre</button>
            <CentresTable centres={centres} viewUrl="/admin/manage-centres" />
          </>
    }
  </>;
};