import { Outlet } from "react-router-dom";
import { userIsAdmin } from "../../helpers/auth";

export default function AdminView() {

  if (!userIsAdmin()) {
    window.location.href = "/";
    return <></>;
  }
    
  return <>
    <Outlet />
  </>;
};