import { useContext, useEffect, useState } from "react";
import { StaffMemberInput } from "../Api/utc-api";
import DetailRow from "./DetailRow";
import { handleElementChange, renderAddress } from "../helpers/general";
import CentreSelectionTable from "./CentreSelectionTable";
import { ApplicationContext } from "../contexts/ApplicationContext";

export default function StaffMemberDetails({ staffMember, isEditing, onSave }:
  { staffMember: StaffMemberInput, isEditing: boolean, onSave?: (staffMember: StaffMemberInput) => void }) {

  const { centres } = useContext(ApplicationContext);
  const [editedStaffMember, setEditedStaffMember] = useState<StaffMemberInput | null>(null);

  useEffect(() => {
    if (isEditing && staffMember)
      setEditedStaffMember({ ...staffMember });
  }, [isEditing, staffMember]);

  const isValid = () => {
    return editedStaffMember?.email?.length &&
      editedStaffMember?.firstName?.length &&
      editedStaffMember?.lastName?.length
  };

  return <>
    <DetailRow title='Account'>
      {
        isEditing
          ? <span>
            <input
              type="text"
              name="email"
              placeholder="Email"
              className="input is-fullwidth mb-2"
              value={editedStaffMember?.email || ''}
              onChange={e => handleElementChange(e, setEditedStaffMember)} />
          </span>
          : <span>{staffMember.email}</span>
      }
    </DetailRow>

    <DetailRow title='Name'>
      {
        isEditing
          ? <span>
            <input
              type="text"
              name="title"
              placeholder="Title"
              className="input is-fullwidth mb-2"
              value={editedStaffMember?.title || ''}
              onChange={e => handleElementChange(e, setEditedStaffMember)} />
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="input is-fullwidth mb-2"
              value={editedStaffMember?.firstName || ''}
              onChange={e => handleElementChange(e, setEditedStaffMember)} />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="input is-fullwidth mb-2"
              value={editedStaffMember?.lastName || ''}
              onChange={e => handleElementChange(e, setEditedStaffMember)} />
          </span>
          : <span>{staffMember.title} {staffMember.firstName} {staffMember.lastName}</span>
      }
    </DetailRow>

    <DetailRow title='Address'>
      {
        isEditing
          ? <>
            <p>
              <input type="text" parent-name="address" name="addressLine1" placeholder="Address Line 1" className="input is-fullwidth mb-2"
                value={editedStaffMember?.address?.addressLine1 || ''} onChange={e => handleElementChange(e, setEditedStaffMember)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="addressLine2" placeholder="Address Line 2" className="input is-fullwidth mb-2"
                value={editedStaffMember?.address?.addressLine2 || ''} onChange={e => handleElementChange(e, setEditedStaffMember)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="city" placeholder="City" className="input is-fullwidth mb-2"
                value={editedStaffMember?.address?.city || ''} onChange={e => handleElementChange(e, setEditedStaffMember)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="postcode" placeholder="Postcode" className="input is-fullwidth mb-2"
                value={editedStaffMember?.address?.postcode || ''} onChange={e => handleElementChange(e, setEditedStaffMember)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="country" placeholder="Country" className="input is-fullwidth mb-2"
                value={editedStaffMember?.address?.country || ''} onChange={e => handleElementChange(e, setEditedStaffMember)} />
            </p>
          </>
          : <>
            <p dangerouslySetInnerHTML={{ __html: renderAddress(staffMember.address) }}></p>
            <p>{staffMember.address?.country}</p>
          </>
      }
    </DetailRow>

    {isEditing && !!centres &&
      <CentreSelectionTable
        centres={centres}
        selectedCentres={editedStaffMember?.assignedCentres || []}
        selectedCentresChanged={assignedCentres => {
          setEditedStaffMember(e => {
            if (!e) return e;
            return { ...e, assignedCentres };
          });
        }} />
    }

    {
      isEditing && onSave && editedStaffMember
        ? <button className="button is-success mb-2" onClick={() => onSave(editedStaffMember)} disabled={!isValid()}>Save</button>
        : <></>
    }
  </>
};