import { BaseAPI, Configuration } from "./utc-api";
import { UTC_API_URL } from '../config/app-config';
import { getAccessToken } from "../helpers/auth";

class UtcApiClient {
  private utcApiUrl: string;

  constructor(utcApiUrl: string) {
    this.utcApiUrl = utcApiUrl;
  }

  private async getUtcApiConfiguration(): Promise<Configuration> {
    return new Configuration({
      basePath: this.utcApiUrl,
      accessToken: `Bearer ${getAccessToken()}`
    })
  }

  async getResourceClient<T extends BaseAPI>(resource: new (configuration: Configuration) => T): Promise<T> {
    const configuration = await this.getUtcApiConfiguration();
    return new resource(configuration);
  }
}

const utcApiClient = new UtcApiClient(UTC_API_URL);

export {
  utcApiClient as UtcApiClient
};