import { useEffect, useState } from "react";
import DetailRow from "./DetailRow";
import { handleElementChange, renderAddress } from "../helpers/general";
import { User } from "../Api/utc-api";

export default function UserDetails({ user, isEditing, onSave }:
  { user: User, isEditing: boolean, onSave?: (user: User) => void }) {

  const [editedUser, setEditedUser] = useState<User | null>(null);

  useEffect(() => {
    if (isEditing && user)
      setEditedUser({ ...user });
  }, [isEditing, user]);

  const isValid = () => {
    return editedUser?.email?.length &&
      editedUser?.firstName?.length &&
      editedUser?.lastName?.length
  };

  return <>
    <DetailRow title='Account'>
      {
        isEditing
          ? <>
            <p>
              <input
                type="text"
                name="email"
                placeholder="Email"
                className="input is-fullwidth mb-2"
                value={editedUser?.email || ''}
                disabled={!!user.id}
                onChange={e => handleElementChange(e, setEditedUser)} />
            </p>
          </>
          : <>
            <p>{user.email}</p>
          </>
      }
    </DetailRow>

    <DetailRow title='Name'>
      {
        isEditing
          ? <span>
            <input
              type="text"
              name="title"
              placeholder="Title"
              className="input is-fullwidth mb-2"
              value={editedUser?.title || ''}
              onChange={e => handleElementChange(e, setEditedUser)} />
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="input is-fullwidth mb-2"
              value={editedUser?.firstName || ''}
              onChange={e => handleElementChange(e, setEditedUser)} />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="input is-fullwidth mb-2"
              value={editedUser?.lastName || ''}
              onChange={e => handleElementChange(e, setEditedUser)} />
          </span>
          : <span>{user.title} {user.firstName} {user.lastName}</span>
      }
    </DetailRow>

    <DetailRow title='Address'>
      {
        isEditing
          ? <>
            <p>
              <input type="text" parent-name="address" name="addressLine1" placeholder="Address Line 1" className="input is-fullwidth mb-2"
                value={editedUser?.address?.addressLine1 || ''} onChange={e => handleElementChange(e, setEditedUser)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="addressLine2" placeholder="Address Line 2" className="input is-fullwidth mb-2"
                value={editedUser?.address?.addressLine2 || ''} onChange={e => handleElementChange(e, setEditedUser)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="city" placeholder="City" className="input is-fullwidth mb-2"
                value={editedUser?.address?.city || ''} onChange={e => handleElementChange(e, setEditedUser)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="postcode" placeholder="Postcode" className="input is-fullwidth mb-2"
                value={editedUser?.address?.postcode || ''} onChange={e => handleElementChange(e, setEditedUser)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="country" placeholder="Country" className="input is-fullwidth mb-2"
                value={editedUser?.address?.country || ''} onChange={e => handleElementChange(e, setEditedUser)} />
            </p>
          </>
          : <>
            <p dangerouslySetInnerHTML={{ __html: renderAddress(user.address) }}></p>
            <p>{user.address?.country}</p>
          </>
      }
    </DetailRow>

    {
      isEditing && onSave && editedUser
        ? <button className="button is-success mb-2" onClick={() => onSave(editedUser)} disabled={!isValid()}>Save</button>
        : <></>
    }
  </>
};