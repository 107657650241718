import { useEffect, useState } from "react";
import { Centre, CentresApi } from "../Api/utc-api";
import { makeRequest } from "../Api/http";
import { getUserAssignedCentres, userIsAdmin } from "../helpers/auth";
import { UtcApiClient } from "../Api/UtcApiClient";
import CentresTable from "../Components/CentresTable";

function YourCentresView() {
  const [centres, setCentres] = useState<Centre[] | null>(null);
  const [yourCentres, setYourCentres] = useState<Centre[] | null>(null);

  useEffect(() => {
    (async () => {
      const centresApi = await UtcApiClient.getResourceClient(CentresApi);
      makeRequest(centresApi.getCentres()).then(setCentres);
    })();
  }, []);

  useEffect(() => {
    if (!centres) return;

    const assignedCentreIds = getUserAssignedCentres();
    const assignedCentres = userIsAdmin() ? centres : centres.filter(_ => assignedCentreIds.includes(_.id));
    setYourCentres(assignedCentres);
  }, [centres]);

  return <>
    <h1 className="title is-2">Your Centres</h1>

    <h3 className="title is-3 mt-6">Available Centres</h3>
    {yourCentres?.length
      ? <CentresTable centres={yourCentres} viewUrl="/your-centres" />
      : 'You have not been assigned to any centres'
    }
    <ul></ul>
  </>;
}

export default YourCentresView;
