/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface NewUserInput
 */
export interface NewUserInput {
    /**
     * 
     * @type {string}
     * @memberof NewUserInput
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUserInput
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUserInput
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUserInput
     */
    sex?: string;
    /**
     * 
     * @type {Address}
     * @memberof NewUserInput
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof NewUserInput
     */
    email: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewUserInput
     */
    roles?: Array<string>;
}

/**
 * Check if a given object implements the NewUserInput interface.
 */
export function instanceOfNewUserInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function NewUserInputFromJSON(json: any): NewUserInput {
    return NewUserInputFromJSONTyped(json, false);
}

export function NewUserInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUserInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'email': json['email'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function NewUserInputToJSON(value?: NewUserInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'sex': value.sex,
        'address': AddressToJSON(value.address),
        'email': value.email,
        'roles': value.roles,
    };
}

