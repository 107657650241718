import { useState } from "react";
import { ConsultationSubmissionComment, ConsultationSubmissionCommentInput } from "../Api/utc-api";
import { formatDateTime } from "../helpers/formatting";
import DetailRow from "./DetailRow";

export default function ConsultationSubmissionComments({ comments, onSubmit }:
  { comments: ConsultationSubmissionComment[], onSubmit: (newComment: ConsultationSubmissionCommentInput) => Promise<void> }) {
  const [showAddComment, setShowAddComment] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<ConsultationSubmissionCommentInput>({ comment: '', isPublic: false });

  const submitComment = async () => {
    if (!newComment) return;

    onSubmit(newComment).then(() => {
      setShowAddComment(false);
      setNewComment({ comment: '', isPublic: false });
    });
  };

  return <>
    <div className="has-text-weight-bold pb-2 mb-2 is-size-6 border-bottom">
      Comments
    </div>

    {!comments?.length
      ? <span>No comments</span>
      : comments.sort((a, b) => a.dateCreated > b.dateCreated ? 1 : -1).map(comment => (
        <Comment comment={comment} key={comment.id} />
      ))}

    <div className="mt-3">
      {
        !showAddComment
          ? <button className="button" onClick={() => setShowAddComment(true)}>Add Comment</button>
          : <div>
            <div>
              <textarea
                value={newComment.comment}
                onChange={e => setNewComment(c => ({ ...c, comment: e.target.value }))}
                className="textarea is-fullwidth" />
            </div>
            <div className="pb-2">
              Public Comment?
              <br />
              <select
                value={newComment.isPublic.toString()}
                onChange={e => setNewComment(c => ({ ...c, isPublic: e.target.value === 'true' }))}
                className="select"
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
            <button className="button" onClick={submitComment}>Submit</button>
            <button className="button" onClick={() => setShowAddComment(false)}>Cancel</button>
          </div>
      }
    </div>
  </>
};

function Comment({ comment }: { comment: ConsultationSubmissionComment }) {
  return <div className="border-bottom mt-2">
    <div className="columns pb-0 mb-0">
      <div className="column pb-0 mb-0">
        <span className="has-text-weight-bold">Date: </span>
        {formatDateTime(comment.dateCreated)}
      </div>
      <div className="column pb-0 mb-0">
        <span className="has-text-weight-bold">Added By: </span>
        {comment.displayName} &nbsp; 

        {
          comment.systemGenerated
            ? <span className="is-italic is-underlined">**System-Generated**</span>
            : ''
        }
      </div>
    </div>

    {
      !comment.isPublic
        ? <div className="pb-2 is-italic">-- Private Comment --</div>
        : ''
    }

    <DetailRow title='Comment:'>
      <p dangerouslySetInnerHTML={{ __html: comment.comment.replace(/\n/g, "<br />") }}></p>
    </DetailRow>
  </div>;
};