import { useEffect, useState } from "react";
import { ConsultationSubmissionStatus } from "../Api/utc-api";
import { getUser } from "../helpers/auth"
import ConsultationSubmissionsTable from "../Components/ConsultationSubmissionsTable";
import { useQuery } from "@tanstack/react-query";
import { ConsultationSubmissionsQuery } from "../Queries/ConsultationSubmissionsQuery";

function DashboardView() {
  const [user, setUser] = useState<any>(null);

  const submissionsQuery = useQuery(ConsultationSubmissionsQuery);

  useEffect(() => {
    (async () => {
      setUser(getUser());
    })();
  }, []);

  return <>
    <h1 className="title is-2">Dashboard</h1>

    <h3 className="title is-3 mt-6">User</h3>
    <div className="subtitle">{user?.name}</div>

    <h3 className="title is-3 mt-6">Unreviewed Submissions</h3>
    {
      submissionsQuery.isLoading
        ? 'Fetching...'
        : <ConsultationSubmissionsTable filterStatuses={[ConsultationSubmissionStatus.Pending]} consultationSubmissionsData={submissionsQuery.data} />
    }

    <h3 className="title is-3 mt-6">In Progress Submissions</h3>
    {
      submissionsQuery.isLoading
        ? 'Fetching...'
        : <ConsultationSubmissionsTable filterStatuses={[ConsultationSubmissionStatus.Prioritised, ConsultationSubmissionStatus.InProgress]} consultationSubmissionsData={submissionsQuery.data} />
    }

  </>;
};

export default DashboardView;
