/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationCentreDetails } from './ConsultationCentreDetails';
import {
    ConsultationCentreDetailsFromJSON,
    ConsultationCentreDetailsFromJSONTyped,
    ConsultationCentreDetailsToJSON,
} from './ConsultationCentreDetails';
import type { ConsultationSubmissionBody } from './ConsultationSubmissionBody';
import {
    ConsultationSubmissionBodyFromJSON,
    ConsultationSubmissionBodyFromJSONTyped,
    ConsultationSubmissionBodyToJSON,
} from './ConsultationSubmissionBody';
import type { ConsultationSubmissionComment } from './ConsultationSubmissionComment';
import {
    ConsultationSubmissionCommentFromJSON,
    ConsultationSubmissionCommentFromJSONTyped,
    ConsultationSubmissionCommentToJSON,
} from './ConsultationSubmissionComment';
import type { ConsultationSubmissionPriority } from './ConsultationSubmissionPriority';
import {
    ConsultationSubmissionPriorityFromJSON,
    ConsultationSubmissionPriorityFromJSONTyped,
    ConsultationSubmissionPriorityToJSON,
} from './ConsultationSubmissionPriority';
import type { ConsultationSubmissionStatus } from './ConsultationSubmissionStatus';
import {
    ConsultationSubmissionStatusFromJSON,
    ConsultationSubmissionStatusFromJSONTyped,
    ConsultationSubmissionStatusToJSON,
} from './ConsultationSubmissionStatus';

/**
 * 
 * @export
 * @interface ConsultationSubmission
 */
export interface ConsultationSubmission {
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmission
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmission
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmission
     */
    consultationFormId: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmission
     */
    centreId: string;
    /**
     * 
     * @type {ConsultationCentreDetails}
     * @memberof ConsultationSubmission
     */
    centre: ConsultationCentreDetails;
    /**
     * 
     * @type {ConsultationSubmissionBody}
     * @memberof ConsultationSubmission
     */
    consultation: ConsultationSubmissionBody;
    /**
     * 
     * @type {ConsultationSubmissionStatus}
     * @memberof ConsultationSubmission
     */
    status: ConsultationSubmissionStatus;
    /**
     * 
     * @type {ConsultationSubmissionPriority}
     * @memberof ConsultationSubmission
     */
    priority?: ConsultationSubmissionPriority;
    /**
     * 
     * @type {Array<ConsultationSubmissionComment>}
     * @memberof ConsultationSubmission
     */
    comments?: Array<ConsultationSubmissionComment>;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmission
     */
    conclusion?: string;
    /**
     * 
     * @type {Date}
     * @memberof ConsultationSubmission
     */
    dateCreated: Date;
    /**
     * 
     * @type {Date}
     * @memberof ConsultationSubmission
     */
    lastModified: Date;
}

/**
 * Check if a given object implements the ConsultationSubmission interface.
 */
export function instanceOfConsultationSubmission(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "consultationFormId" in value;
    isInstance = isInstance && "centreId" in value;
    isInstance = isInstance && "centre" in value;
    isInstance = isInstance && "consultation" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "dateCreated" in value;
    isInstance = isInstance && "lastModified" in value;

    return isInstance;
}

export function ConsultationSubmissionFromJSON(json: any): ConsultationSubmission {
    return ConsultationSubmissionFromJSONTyped(json, false);
}

export function ConsultationSubmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationSubmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'consultationFormId': json['consultationFormId'],
        'centreId': json['centreId'],
        'centre': ConsultationCentreDetailsFromJSON(json['centre']),
        'consultation': ConsultationSubmissionBodyFromJSON(json['consultation']),
        'status': ConsultationSubmissionStatusFromJSON(json['status']),
        'priority': !exists(json, 'priority') ? undefined : ConsultationSubmissionPriorityFromJSON(json['priority']),
        'comments': !exists(json, 'comments') ? undefined : ((json['comments'] as Array<any>).map(ConsultationSubmissionCommentFromJSON)),
        'conclusion': !exists(json, 'conclusion') ? undefined : json['conclusion'],
        'dateCreated': (new Date(json['dateCreated'])),
        'lastModified': (new Date(json['lastModified'])),
    };
}

export function ConsultationSubmissionToJSON(value?: ConsultationSubmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'consultationFormId': value.consultationFormId,
        'centreId': value.centreId,
        'centre': ConsultationCentreDetailsToJSON(value.centre),
        'consultation': ConsultationSubmissionBodyToJSON(value.consultation),
        'status': ConsultationSubmissionStatusToJSON(value.status),
        'priority': ConsultationSubmissionPriorityToJSON(value.priority),
        'comments': value.comments === undefined ? undefined : ((value.comments as Array<any>).map(ConsultationSubmissionCommentToJSON)),
        'conclusion': value.conclusion,
        'dateCreated': (value.dateCreated.toISOString()),
        'lastModified': (value.lastModified.toISOString()),
    };
}

