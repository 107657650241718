import { useEffect, useState } from "react";
import { CentreInput } from "../Api/utc-api";
import DetailRow from "../Components/DetailRow";
import { centreAddress, centreWaitingTime } from "../helpers/centres";
import { handleElementChange } from "../helpers/general";

export default function CentreDetails({ centre, isEditing, onSave }:
  { centre: CentreInput, isEditing: boolean, onSave?: (centre: CentreInput) => void }) {
  const [editedCentre, setEditedCentre] = useState<CentreInput | null>(null);

  useEffect(() => {
    if (isEditing && centre)
      setEditedCentre({ ...centre });
  }, [isEditing, centre]);

  return <>
    <DetailRow title='Name'>
      {
        isEditing
          ? <input
            type="text"
            name="name"
            className="input is-fullwidth mb-2"
            value={editedCentre?.name || ''}
            onChange={e => handleElementChange(e, setEditedCentre)} />
          : centre.name
      }
    </DetailRow>

    <DetailRow title='Address'>
      {
        isEditing
          ? <>
            <p>
              <input type="text" parent-name="address" name="addressLine1" placeholder="Address Line 1" className="input is-fullwidth mb-2"
                value={editedCentre?.address?.addressLine1 || ''} onChange={e => handleElementChange(e, setEditedCentre)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="addressLine2" placeholder="Address Line 2" className="input is-fullwidth mb-2"
                value={editedCentre?.address?.addressLine2 || ''} onChange={e => handleElementChange(e, setEditedCentre)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="city" placeholder="City" className="input is-fullwidth mb-2"
                value={editedCentre?.address?.city || ''} onChange={e => handleElementChange(e, setEditedCentre)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="postcode" placeholder="Postcode" className="input is-fullwidth mb-2"
                value={editedCentre?.address?.postcode || ''} onChange={e => handleElementChange(e, setEditedCentre)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="country" placeholder="Country" className="input is-fullwidth mb-2"
                value={editedCentre?.address?.country || ''} onChange={e => handleElementChange(e, setEditedCentre)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="latitude" placeholder="Latitude" className="input is-fullwidth mb-2"
                value={editedCentre?.address?.latitude || ''} onChange={e => handleElementChange(e, setEditedCentre)} />
            </p>
            <p>
              <input type="text" parent-name="address" name="longitude" placeholder="Longitude" className="input is-fullwidth mb-2"
                value={editedCentre?.address?.longitude || ''} onChange={e => handleElementChange(e, setEditedCentre)} />
            </p>
          </>
          : <>
            <p dangerouslySetInnerHTML={{ __html: centreAddress(centre) }}></p>
            <p>{centre.address?.country}</p>
            <br />
            {
              centre.address?.latitude && centre.address?.longitude
                ? <p>  Latitude/Longitude: {centre.address?.latitude},{centre.address?.longitude}</p>
                : <></>
            }
          </>
      }
    </DetailRow>

    <DetailRow title='Approx. Waiting Time'>
      {
        isEditing
          ? <>
            <input
              parent-name="status"
              name="estimatedWaitingTime"
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              className="input"
              value={editedCentre?.status?.estimatedWaitingTime || ''}
              onChange={e => handleElementChange(e, setEditedCentre)}
            />
          </>
          :
          <>
            {centreWaitingTime(centre)}
          </>
      }
    </DetailRow>

    {
      isEditing && onSave && editedCentre
        ? <button className="button is-success mb-2" onClick={() => onSave(editedCentre)}>Save</button>
        : <></>
    }
  </>
};