import { useCallback, useEffect, useState } from "react";
import { Centre, CentreStatus, CentresApi, ConsultationSubmissionStatus } from "../Api/utc-api";
import { makeRequest } from "../Api/http";
import { UtcApiClient } from "../Api/UtcApiClient";
import { useParams } from "react-router-dom";
import ConsultationSubmissionsTable from "../Components/ConsultationSubmissionsTable";
import DetailRow from "../Components/DetailRow";
import { centreAddress, centreWaitingTime } from "../helpers/centres";
import { useQuery } from "@tanstack/react-query";
import { ConsultationSubmissionsQuery } from "../Queries/ConsultationSubmissionsQuery";
import { handleElementChange } from "../helpers/general";

function YourCentreView() {
  const { id } = useParams();

  const [centre, setCentre] = useState<Centre | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedStatus, setEditedStatus] = useState<CentreStatus | null>(null);

  const submissionsQuery = useQuery(ConsultationSubmissionsQuery);

  const refreshCentre = useCallback(async () => {
    if (!id) return;

    const centresApi = await UtcApiClient.getResourceClient(CentresApi);
    makeRequest(centresApi.getCentre({ id })).then(setCentre);
  }, [id]);

  useEffect(() => {
    if (!id) return;

    refreshCentre();
  }, [id, refreshCentre]);

  useEffect(() => {
    if (isEditing && centre)
      setEditedStatus({ ...centre.status });
  }, [isEditing, centre]);

  if (!centre)
    return <div>Loading...</div>;

  const updateCentreStatus = async () => {
    if (!id || !editedStatus) return;

    const centresApi = await UtcApiClient.getResourceClient(CentresApi);
    makeRequest(centresApi.updateCentreStatus({ id, centreStatus: editedStatus })).then(() => {
      refreshCentre();
      setIsEditing(false);
    });
  };

  const editedStatusIsValid = () =>
    editedStatus?.estimatedWaitingTime && !isNaN(editedStatus?.estimatedWaitingTime);

  return <>
    <h1 className="title is-2">{centre.name}</h1>

    <div className="section-container">
      <DetailRow title='Address'>
        <p dangerouslySetInnerHTML={{ __html: centreAddress(centre) }}></p>
      </DetailRow>

      <DetailRow title='Approx. Waiting Time'>
        {
          isEditing
            ? <>
              <input
                name="estimatedWaitingTime"
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                className="input"
                value={editedStatus?.estimatedWaitingTime || ''}
                onChange={e => handleElementChange(e, setEditedStatus)}
              />
              <p>
                <button className="button" onClick={() => setIsEditing(false)}>Cancel</button>
                <button className="button" disabled={!editedStatusIsValid()} onClick={updateCentreStatus}>Update</button>
              </p>
            </>
            :
            <>
              {centreWaitingTime(centre)}
              <p>
                <button className="button" onClick={() => setIsEditing(true)}>Edit</button>
              </p>
            </>
        }
      </DetailRow>
    </div>

    <h3 className="title is-3 mt-6">Unreviewed Submissions</h3>
    {
      submissionsQuery.isLoading
        ? 'Fetching...'
        : <ConsultationSubmissionsTable centreId={id} filterStatuses={[ConsultationSubmissionStatus.Pending]} consultationSubmissionsData={submissionsQuery.data} />
    }

    <h3 className="title is-3 mt-6">In Progress Submissions</h3>
    {
      submissionsQuery.isLoading
        ? 'Fetching...'
        : <ConsultationSubmissionsTable centreId={id} filterStatuses={[ConsultationSubmissionStatus.Prioritised, ConsultationSubmissionStatus.InProgress]} consultationSubmissionsData={submissionsQuery.data} />
    }

    <h3 className="title is-3 mt-6">Completed Submissions</h3>
    {
      submissionsQuery.isLoading
        ? 'Fetching...'
        : <ConsultationSubmissionsTable centreId={id} filterStatuses={[ConsultationSubmissionStatus.Completed]} consultationSubmissionsData={submissionsQuery.data} />
    }
  </>;
};

export default YourCentreView;
