import { Link } from "react-router-dom";
import { StaffMember } from "../Api/utc-api";
import { useContext } from "react";
import { ApplicationContext } from "../contexts/ApplicationContext";

export default function StaffTable({ staff, viewUrl, showAssignedCentres = true }:
  { staff: StaffMember[], viewUrl?: string, showAssignedCentres?: boolean }) {

  const { centres } = useContext(ApplicationContext);

  const getStaffUrl = (staffMember: StaffMember) => {
    if (!viewUrl) return '';
    return `${viewUrl}${!viewUrl.endsWith('/') ? '/' : ''}${staffMember.id}`;
  };

  const assignedCentres = (staffMember: StaffMember) => {
    if (!centres)
      return "";

    return centres.filter(_ => staffMember.assignedCentres?.includes(_.id)).map(_ => _.name).join(', ');
  };

  return (
    <table className="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          {showAssignedCentres && <th>Assigned Centres</th>}
          {!!viewUrl && <th></th>}
        </tr>
      </thead>
      <tbody>
        {
          !staff
            ? <tr><td colSpan={6}>Loading...</td></tr>
            : !staff.length
              ? <tr><td colSpan={6}>No staff members</td></tr>
              : staff.map(user => (
                <tr key={user.id}>
                  <td>{user.title} {user.firstName} {user.lastName}</td>
                  {showAssignedCentres && <td>{assignedCentres(user)}</td>}
                  {!!viewUrl && <td><Link to={getStaffUrl(user)}>View</Link></td>}
                </tr>
              ))
        }
      </tbody>
    </table>
  );
};