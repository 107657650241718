import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import StaffTable from "../../Components/StaffTable";
import { UtcApiClient } from "../../Api/UtcApiClient";
import { StaffApi, StaffMemberInput } from "../../Api/utc-api";
import { useState } from "react";
import StaffMemberDetails from "../../Components/StaffMemberDetails";

export default function ManageStaffView() {
  const [showAddStaffMember, setShowAddStaffMember] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { data: staff } = useQuery({
    queryKey: ['staff'],
    queryFn: async () => {
      const staffApi = await UtcApiClient.getResourceClient(StaffApi);
      return await staffApi.getStaff();
    },
    refetchOnWindowFocus: false
  });

  const { mutate: addStaffMember } = useMutation({
    mutationFn: async (newStaffMember: StaffMemberInput) => {
      const staffApi = await UtcApiClient.getResourceClient(StaffApi);
      await staffApi.createStaffMember({ staffMemberInput: newStaffMember });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['staff']);
      setShowAddStaffMember(false);
    },
    onError: () => {
      alert("An error occurred while adding staff member");
    }
  });

  return <>
    <h1 className="title is-2">Manage Staff</h1>

    {
      showAddStaffMember
        ? <>
          <StaffMemberDetails staffMember={{ firstName: '', lastName: '', email: '' }} isEditing={true} onSave={newStaffMember => addStaffMember(newStaffMember)} />
          <p>
            <button className="button" onClick={() => setShowAddStaffMember(false)}>Cancel</button>
          </p>
        </>
        : !staff
          ? <>Loading...</>
          : <>
            <button className="button is-success mb-2" onClick={() => setShowAddStaffMember(true)}>Add Staff Member</button>
            <StaffTable staff={staff} viewUrl="/admin/manage-staff" />
          </>
    }
  </>;
};