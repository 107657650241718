import { Centre } from "../Api/utc-api";
import { centreAddress } from "../helpers/centres";

export default function CentreSelectionTable({ centres, selectedCentres, selectedCentresChanged }:
  { centres: Centre[], selectedCentres: string[], selectedCentresChanged: (centreIds: string[]) => void }) {

  const selectionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.target.checked as boolean;
    const centreId = e.target.value as string;

    const updatedCentres = selected
      ? [...selectedCentres, centreId]
      : selectedCentres.filter(_ => _ !== centreId);    
    
    selectedCentresChanged([...new Set(updatedCentres)]);
  };

  return (
    <table className="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          <th>Address</th>
          <th>Selected</th>
        </tr>
      </thead>
      <tbody>
        {centres.map(centre => (
          <tr key={centre.id}>
            <td>{centre.name}</td>
            <td dangerouslySetInnerHTML={{ __html: centreAddress(centre) }}></td>
            <td>
              <input type="checkbox" checked={selectedCentres.includes(centre.id)} value={centre.id} onChange={selectionChanged} />
            </td>
          </tr>
        ))
        }
      </tbody>
    </table>
  );
};