/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StaffMember,
  StaffMemberInput,
} from '../models';
import {
    StaffMemberFromJSON,
    StaffMemberToJSON,
    StaffMemberInputFromJSON,
    StaffMemberInputToJSON,
} from '../models';

export interface CreateStaffMemberRequest {
    staffMemberInput: StaffMemberInput;
}

export interface DeleteStaffMemberRequest {
    id: string;
}

export interface GetStaffRequest {
    centreId?: Array<string>;
}

export interface GetStaffMemberRequest {
    id: string;
}

export interface UpdateStaffMemberRequest {
    id: string;
    staffMemberInput: StaffMemberInput;
}

/**
 * 
 */
export class StaffApi extends runtime.BaseAPI {

    /**
     * Create staff member
     */
    async createStaffMemberRaw(requestParameters: CreateStaffMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffMember>> {
        if (requestParameters.staffMemberInput === null || requestParameters.staffMemberInput === undefined) {
            throw new runtime.RequiredError('staffMemberInput','Required parameter requestParameters.staffMemberInput was null or undefined when calling createStaffMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/staff`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StaffMemberInputToJSON(requestParameters.staffMemberInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffMemberFromJSON(jsonValue));
    }

    /**
     * Create staff member
     */
    async createStaffMember(requestParameters: CreateStaffMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffMember> {
        const response = await this.createStaffMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete staff member
     */
    async deleteStaffMemberRaw(requestParameters: DeleteStaffMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteStaffMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/staff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete staff member
     */
    async deleteStaffMember(requestParameters: DeleteStaffMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStaffMemberRaw(requestParameters, initOverrides);
    }

    /**
     * Get staff
     */
    async getStaffRaw(requestParameters: GetStaffRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StaffMember>>> {
        const queryParameters: any = {};

        if (requestParameters.centreId) {
            queryParameters['centreId'] = requestParameters.centreId.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/staff`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StaffMemberFromJSON));
    }

    /**
     * Get staff
     */
    async getStaff(requestParameters: GetStaffRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StaffMember>> {
        const response = await this.getStaffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get staff member
     */
    async getStaffMemberRaw(requestParameters: GetStaffMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StaffMember>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStaffMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/staff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StaffMemberFromJSON(jsonValue));
    }

    /**
     * Get staff member
     */
    async getStaffMember(requestParameters: GetStaffMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StaffMember> {
        const response = await this.getStaffMemberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update staff member
     */
    async updateStaffMemberRaw(requestParameters: UpdateStaffMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateStaffMember.');
        }

        if (requestParameters.staffMemberInput === null || requestParameters.staffMemberInput === undefined) {
            throw new runtime.RequiredError('staffMemberInput','Required parameter requestParameters.staffMemberInput was null or undefined when calling updateStaffMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oAuth2", []);
        }

        const response = await this.request({
            path: `/staff/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StaffMemberInputToJSON(requestParameters.staffMemberInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update staff member
     */
    async updateStaffMember(requestParameters: UpdateStaffMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateStaffMemberRaw(requestParameters, initOverrides);
    }

}
