import { useCallback, useEffect, useState } from "react";
import { ConsultationSubmissionStatus } from "../Api/utc-api";
import { handleApiError } from "../Api/http";
import { formatStatusValue, formatDateTime } from "../helpers/formatting";
import { Link } from "react-router-dom";
import { ConsultationSubmissionExt } from "../Types/ConsultationSubmissionExt";
import { getAllConsultationSubmissions } from "../Queries/ConsultationSubmissionsQuery";

export default function ConsultationSubmissionsTable({ centreId, filterStatuses, consultationSubmissionsData }:
  { centreId?: string, filterStatuses?: ConsultationSubmissionStatus[], consultationSubmissionsData?: ConsultationSubmissionExt[] }) {
  const [consultationSubmissions, setConsultationSubmissions] = useState<ConsultationSubmissionExt[] | null>(null);

  const getConsultationSubmissionData = useCallback(async () => {
    let data: ConsultationSubmissionExt[] = [];

    try {
      data = consultationSubmissionsData
        ? consultationSubmissionsData.filter(_ =>
          (!centreId || _.centreId === centreId) && (!filterStatuses || filterStatuses.includes(_.status)))
        : await getAllConsultationSubmissions(centreId, filterStatuses);
    } catch (e) {
      handleApiError(e);
    }

    setConsultationSubmissions(data);
  }, [centreId, filterStatuses, consultationSubmissionsData]);

  useEffect(() => {
    getConsultationSubmissionData();
  }, [getConsultationSubmissionData]);

  return <>
    <table className="table">
      <thead>
        <tr>
          <th>Status</th>
          <th>Priority</th>
          <th>Date</th>
          <th>Submitted By</th>
          <th>Location</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          !consultationSubmissions
            ? <tr><td colSpan={6}>Loading...</td></tr>
            : !consultationSubmissions.length
              ? <tr><td colSpan={6}>No consultations submissions</td></tr>
              : consultationSubmissions.map(submission => (
                <tr key={submission.id}>
                  <td>{formatStatusValue(submission.status)}</td>
                  <td>{submission.priority || 'N/A'}</td>
                  <td>{formatDateTime(submission.dateCreated)}</td>
                  <td>{submission.submitter}</td>
                  <td>{submission.centre.name}</td>
                  <td><Link to={`/consultation-submission/${submission.id}`}>View</Link></td>
                </tr>
              ))
        }
      </tbody>
    </table>
  </>
};