import { useState } from "react";
import { UserInput, UsersApi } from "../../Api/utc-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleApiError } from "../../Api/http";
import { UtcApiClient } from "../../Api/UtcApiClient";
import { useNavigate, useParams } from "react-router-dom";
import UserDetails from "../../Components/UserDetails";

export default function ManageAdminView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { mutate: updateUser } = useMutation({
    mutationFn: async (editedUser: UserInput) => {
      if (!id)
        throw new Error("Invalid data");

      const usersApi = await UtcApiClient.getResourceClient(UsersApi);
      await usersApi.updateUser({ id, userInput: editedUser });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    },
    onError: () => {
      alert("An error occurred while updating admin");
    },
    onSettled: () => {
      setIsEditing(false);
    }
  });

  const { mutate: deleteUser } = useMutation({
    mutationFn: async () => {
      if (!id)
        throw new Error("Invalid data");

      const usersApi = await UtcApiClient.getResourceClient(UsersApi);
      await usersApi.deleteUser({ id });
    },
    onSuccess: () => {
      navigate('/admin/manage-admins');
      setTimeout(() =>  queryClient.invalidateQueries(['users']), 500);
    },
    onError: () => {
      alert("An error occurred while deleting admin");
    }
  });

  const { data: user } = useQuery({
    queryKey: ['users', { userId: id }],
    queryFn: async () => {
      try {
        const usersApi = await UtcApiClient.getResourceClient(UsersApi);
        return await usersApi.getUser({ id: id as string });
      } catch (e) {
        handleApiError(e);
        throw e;
      }
    },
    enabled: !!id,
    refetchOnWindowFocus: false
  });

  if (!user)
    return <>Loading...</>;

  if (isEditing)
    return (<>
      <div className="section-container">
        <UserDetails user={user} isEditing={isEditing} onSave={editedUser => updateUser(editedUser)} />
        <button className="button ml-2" onClick={() => setIsEditing(false)}>Cancel</button>

      </div>
    </>);

  return <>
    <h1 className="title is-2">Manage Admin</h1>

    <div className="section-container">
      <UserDetails user={user} isEditing={isEditing} onSave={editedUser => updateUser(editedUser)} />
      <p>
        <button className="button" onClick={() => setIsEditing(true)}>Edit</button>
        <button className="button is-danger ml-2" onClick={() => {
          if (window.confirm("Are you sure you wish to delete this admin?"))
            deleteUser();
        }}>Delete</button>
      </p>
    </div>
  </>;
};