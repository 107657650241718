/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface ConsultationCentreDetails
 */
export interface ConsultationCentreDetails {
    /**
     * 
     * @type {string}
     * @memberof ConsultationCentreDetails
     */
    name?: string;
    /**
     * 
     * @type {Address}
     * @memberof ConsultationCentreDetails
     */
    address?: Address;
}

/**
 * Check if a given object implements the ConsultationCentreDetails interface.
 */
export function instanceOfConsultationCentreDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConsultationCentreDetailsFromJSON(json: any): ConsultationCentreDetails {
    return ConsultationCentreDetailsFromJSONTyped(json, false);
}

export function ConsultationCentreDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationCentreDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
    };
}

export function ConsultationCentreDetailsToJSON(value?: ConsultationCentreDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': AddressToJSON(value.address),
    };
}

