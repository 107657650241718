/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationSubmissionInputBody } from './ConsultationSubmissionInputBody';
import {
    ConsultationSubmissionInputBodyFromJSON,
    ConsultationSubmissionInputBodyFromJSONTyped,
    ConsultationSubmissionInputBodyToJSON,
} from './ConsultationSubmissionInputBody';

/**
 * 
 * @export
 * @interface ConsultationSubmissionInput
 */
export interface ConsultationSubmissionInput {
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionInput
     */
    consultationFormId: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionInput
     */
    centreId: string;
    /**
     * 
     * @type {ConsultationSubmissionInputBody}
     * @memberof ConsultationSubmissionInput
     */
    consultation: ConsultationSubmissionInputBody;
}

/**
 * Check if a given object implements the ConsultationSubmissionInput interface.
 */
export function instanceOfConsultationSubmissionInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "consultationFormId" in value;
    isInstance = isInstance && "centreId" in value;
    isInstance = isInstance && "consultation" in value;

    return isInstance;
}

export function ConsultationSubmissionInputFromJSON(json: any): ConsultationSubmissionInput {
    return ConsultationSubmissionInputFromJSONTyped(json, false);
}

export function ConsultationSubmissionInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationSubmissionInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'consultationFormId': json['consultationFormId'],
        'centreId': json['centreId'],
        'consultation': ConsultationSubmissionInputBodyFromJSON(json['consultation']),
    };
}

export function ConsultationSubmissionInputToJSON(value?: ConsultationSubmissionInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'consultationFormId': value.consultationFormId,
        'centreId': value.centreId,
        'consultation': ConsultationSubmissionInputBodyToJSON(value.consultation),
    };
}

