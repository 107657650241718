import { useCallback, useEffect, useState } from "react";
import {
  ConsultationFormQuestionType,
  ConsultationSubmission,
  ConsultationSubmissionCommentInput,
  ConsultationSubmissionPriority,
  ConsultationSubmissionStageQuestion,
  ConsultationSubmissionStatus,
  ConsultationSubmissionsApi,
  User,
  UsersApi
} from "../Api/utc-api";
import { makeRequest } from "../Api/http";
import { UtcApiClient } from "../Api/UtcApiClient";
import { useParams } from "react-router-dom";
import { formatStatusValue, formatDate, formatDateTime } from "../helpers/formatting";
import { centreAddress } from "../helpers/centres";
import DetailRow from "../Components/DetailRow";
import ConsultationSubmissionComments from "../Components/ConsultationSubmissionComments";
import { constantsToArray } from "../helpers/general";

const getSubmissionStatusValues = (consultationSubmission: ConsultationSubmission): ConsultationSubmissionStatusValues => ({
  status: consultationSubmission.status,
  priority: consultationSubmission.priority,
  conclusion: consultationSubmission.conclusion,
});

type ConsultationSubmissionStatusValues = {
  status?: ConsultationSubmissionStatus;
  priority?: ConsultationSubmissionPriority;
  conclusion?: string;
};

function ConsultationSubmissionView() {
  const { id } = useParams();

  const [consultationSubmission, setConsultationSubmission] = useState<ConsultationSubmission | null>(null);
  const [submitter, setSubmitter] = useState<User | null>(null);
  const [editingStatus, setEditingStatus] = useState<boolean>(false);
  const [editedStatus, setEditedStatus] = useState<ConsultationSubmissionStatusValues | null>(null);

  const refreshConsultationSubmission = useCallback(async () => {
    if (!id) return;
    const consultationSubmissionsApi = await UtcApiClient.getResourceClient(ConsultationSubmissionsApi);
    makeRequest(consultationSubmissionsApi.getConsultationSubmission({ id })).then(setConsultationSubmission);
  }, [id]);

  useEffect(() => {
    refreshConsultationSubmission();
  }, [refreshConsultationSubmission]);

  useEffect(() => {
    if (!consultationSubmission) return;

    (async () => {
      const usersApi = await UtcApiClient.getResourceClient(UsersApi);
      makeRequest(usersApi.getUser({ id: consultationSubmission.userId })).then(setSubmitter);
    })();
  }, [consultationSubmission]);

  useEffect(() => {
    if (editingStatus && consultationSubmission)
      setEditedStatus(getSubmissionStatusValues(consultationSubmission));
  }, [editingStatus, consultationSubmission]);

  const updateConsultationSubmissionStatus = async () => {
    if (!id || !editedStatus) return;

    const consultationSubmissionsApi = await UtcApiClient.getResourceClient(ConsultationSubmissionsApi);

    makeRequest(consultationSubmissionsApi.updateConsultationSubmissionStatus({
      id,
      consultationSubmissionStatusUpdate: editedStatus
    }))
      .then(() => {
        refreshConsultationSubmission();
        setEditingStatus(false);
      });
  };

  const postComment = async (consultationSubmissionCommentInput: ConsultationSubmissionCommentInput) => {
    if (!id) return;

    const consultationSubmissionsApi = await UtcApiClient.getResourceClient(ConsultationSubmissionsApi);

    makeRequest(consultationSubmissionsApi.addConsultationSubmissionComment({
      id,
      consultationSubmissionCommentInput
    }))
      .then(submittedComment => refreshConsultationSubmission());
  };


  if (!id || !consultationSubmission || !submitter)
    return <div>Loading...</div>;

  return <>
    <h1 className="title is-2">Consultation Submission</h1>

    <div className="section-container">
      <DetailRow title='Centre'>
        <p>{consultationSubmission.centre.name}</p>
        <p dangerouslySetInnerHTML={{ __html: centreAddress(consultationSubmission.centre) }}></p>
      </DetailRow>

      <DetailRow title='Date Submitted'>
        {formatDateTime(consultationSubmission.dateCreated)}
      </DetailRow>

      <DetailRow title='Status'>
        {editingStatus
          ? <select
            value={editedStatus?.status}
            onChange={e => setEditedStatus(s => ({ ...s, status: e.target.value as ConsultationSubmissionStatus }))}>
            {constantsToArray(ConsultationSubmissionStatus).map(status =>
              <option key={status} value={status}>{formatStatusValue(status)}</option>)}
          </select>
          : formatStatusValue(consultationSubmission.status)
        }
      </DetailRow>

      <DetailRow title='Priority'>
        {editingStatus
          ? <select
            value={editedStatus?.priority}
            onChange={e => setEditedStatus(s => ({ ...s, priority: e.target.value as ConsultationSubmissionPriority }))}>
            {constantsToArray(ConsultationSubmissionPriority).map(priority =>
              <option key={priority} value={priority}>{priority}</option>)}
          </select>
          : consultationSubmission.priority || '-- Unprioritised --'
        }
      </DetailRow>

      <DetailRow title='Conclusion'>
        {editingStatus
          ? <textarea
            value={editedStatus?.conclusion}
            onChange={e => setEditedStatus(s => ({ ...s, conclusion: e.target.value }))}
            className="textarea is-fullwidth" />
          : consultationSubmission.conclusion || '-- N/A --'
        }
      </DetailRow>

      {
        editingStatus
          ? <>
            <button className="button" onClick={() => setEditingStatus(false)}>Cancel</button>
            <button className="button" onClick={updateConsultationSubmissionStatus}>Save</button>
          </>
          : <button className="button" onClick={() => setEditingStatus(true)}>Edit</button>
      }
    </div>

    <div className="section-container mt-5">
      <ConsultationSubmissionComments
        comments={consultationSubmission.comments || []}
        onSubmit={postComment}
      />
    </div>

    <div className="section-container mt-5">
      <div className="has-text-weight-bold pb-2 mb-2 is-size-6 border-bottom">
        Consultation
      </div>

      {consultationSubmission.consultation.stages.map(stage =>
        <div className="mb-5" key={stage.name}>
          <span className="mb-3 title is-6 is-underlined">
            {stage.name}
          </span>
          {stage.questions.map(question => <QuestionAnswer question={question} key={question.id} />)}
        </div>)}
    </div>
  </>;
}

function QuestionAnswer({ question }: { question: ConsultationSubmissionStageQuestion }) {
  return (
    <div className="mt-2">
      <div className="has-text-weight-bold">{question.question}</div>
      {
        question.type === ConsultationFormQuestionType.SelectMultiple
          ? <span dangerouslySetInnerHTML={{ __html: (question.answer && (JSON.parse(question.answer) as string[]).join('<br />')) || '' }}></span>
          : question.type === ConsultationFormQuestionType.Date
            ? <span>{question.answer && formatDate(new Date(question.answer))}</span>
            : <span dangerouslySetInnerHTML={{ __html: question.answer?.replace(/\n/g, "<br />") || '' }}></span>
      }
    </div>
  )
};

export default ConsultationSubmissionView;
