/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConsultationFormQuestionType = {
    Text: 'text',
    Description: 'description',
    Number: 'number',
    Date: 'date',
    Select: 'select',
    SelectMultiple: 'select_multiple'
} as const;
export type ConsultationFormQuestionType = typeof ConsultationFormQuestionType[keyof typeof ConsultationFormQuestionType];


export function ConsultationFormQuestionTypeFromJSON(json: any): ConsultationFormQuestionType {
    return ConsultationFormQuestionTypeFromJSONTyped(json, false);
}

export function ConsultationFormQuestionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationFormQuestionType {
    return json as ConsultationFormQuestionType;
}

export function ConsultationFormQuestionTypeToJSON(value?: ConsultationFormQuestionType | null): any {
    return value as any;
}

