import { Link } from "react-router-dom";
import { Centre } from "../Api/utc-api";
import { centreAddress, centreWaitingTime } from "../helpers/centres";

export default function CentresTable({ centres, viewUrl }: { centres: Centre[], viewUrl?: string }) {
  const getCentreUrl = (centre: Centre) => {
    if (!viewUrl) return '';
    return `${viewUrl}${!viewUrl.endsWith('/') ? '/' : ''}${centre.id}`;
  };

  return (
    <table className="table is-fullwidth">
      <thead>
        <tr>
          <th>Name</th>
          <th>Address</th>
          <th>Current Wait</th>
          {!!viewUrl && <th></th>}
        </tr>
      </thead>
      <tbody>
        {
          !centres
            ? <tr><td colSpan={6}>Loading...</td></tr>
            : !centres.length
              ? <tr><td colSpan={6}>No centres available</td></tr>
              : centres.map(centre => (
                <tr key={centre.id}>
                  <td>{centre.name}</td>
                  <td dangerouslySetInnerHTML={{ __html: centreAddress(centre) }}></td>
                  <td>{centreWaitingTime(centre)}</td>
                  {!!viewUrl && <td><Link to={getCentreUrl(centre)}>View</Link></td>}
                </tr>
              ))
        }
      </tbody>
    </table>
  );
};