import { ReactNode } from "react";

export default function DetailRow({ title, children }: { title: string, children?: ReactNode }) {
  return (
    <>
      <div className="is-flex is-flex-direction-row">
        <div className="has-text-weight-bold">{title}</div>
      </div>
      <div className="is-flex is-flex-direction-row mb-3">
        <div className="pl-4 is-two-thirds column">{children}</div>
      </div>
    </>
  );
}