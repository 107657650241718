import { createContext } from "react";
import { Centre, CentresApi } from "../Api/utc-api";
import { useQuery } from "@tanstack/react-query";
import { UtcApiClient } from "../Api/UtcApiClient";
import { handleApiError } from "../Api/http";

export type ApplicationContent = {
  centres: Centre[] | undefined
};

export const ApplicationContext = createContext<ApplicationContent>({
  centres: undefined
});

export const ApplicationProvider = ({ children }: any) => {
  const centresQuery = useQuery({
    queryKey: ['centres'],
    queryFn: async () => {
      try {
        const centresApi = await UtcApiClient.getResourceClient(CentresApi);
        return await centresApi.getCentres();
      } catch (e) {
        handleApiError(e);
        throw e;
      }
    },
    refetchOnWindowFocus: false,
    refetchInterval: 120000
  });

  const providerValue = {
    centres: centresQuery.data
  };

  return <ApplicationContext.Provider value={providerValue}>
    {children}
  </ApplicationContext.Provider>
}

