/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationSubmissionInputStageQuestion } from './ConsultationSubmissionInputStageQuestion';
import {
    ConsultationSubmissionInputStageQuestionFromJSON,
    ConsultationSubmissionInputStageQuestionFromJSONTyped,
    ConsultationSubmissionInputStageQuestionToJSON,
} from './ConsultationSubmissionInputStageQuestion';

/**
 * 
 * @export
 * @interface ConsultationSubmissionInputStage
 */
export interface ConsultationSubmissionInputStage {
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionInputStage
     */
    name: string;
    /**
     * 
     * @type {Array<ConsultationSubmissionInputStageQuestion>}
     * @memberof ConsultationSubmissionInputStage
     */
    questions: Array<ConsultationSubmissionInputStageQuestion>;
}

/**
 * Check if a given object implements the ConsultationSubmissionInputStage interface.
 */
export function instanceOfConsultationSubmissionInputStage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "questions" in value;

    return isInstance;
}

export function ConsultationSubmissionInputStageFromJSON(json: any): ConsultationSubmissionInputStage {
    return ConsultationSubmissionInputStageFromJSONTyped(json, false);
}

export function ConsultationSubmissionInputStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationSubmissionInputStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'questions': ((json['questions'] as Array<any>).map(ConsultationSubmissionInputStageQuestionFromJSON)),
    };
}

export function ConsultationSubmissionInputStageToJSON(value?: ConsultationSubmissionInputStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'questions': ((value.questions as Array<any>).map(ConsultationSubmissionInputStageQuestionToJSON)),
    };
}

