/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationSubmissionPriority } from './ConsultationSubmissionPriority';
import {
    ConsultationSubmissionPriorityFromJSON,
    ConsultationSubmissionPriorityFromJSONTyped,
    ConsultationSubmissionPriorityToJSON,
} from './ConsultationSubmissionPriority';
import type { ConsultationSubmissionStatus } from './ConsultationSubmissionStatus';
import {
    ConsultationSubmissionStatusFromJSON,
    ConsultationSubmissionStatusFromJSONTyped,
    ConsultationSubmissionStatusToJSON,
} from './ConsultationSubmissionStatus';

/**
 * 
 * @export
 * @interface ConsultationSubmissionStatusUpdate
 */
export interface ConsultationSubmissionStatusUpdate {
    /**
     * 
     * @type {ConsultationSubmissionStatus}
     * @memberof ConsultationSubmissionStatusUpdate
     */
    status?: ConsultationSubmissionStatus;
    /**
     * 
     * @type {ConsultationSubmissionPriority}
     * @memberof ConsultationSubmissionStatusUpdate
     */
    priority?: ConsultationSubmissionPriority;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionStatusUpdate
     */
    conclusion?: string;
}

/**
 * Check if a given object implements the ConsultationSubmissionStatusUpdate interface.
 */
export function instanceOfConsultationSubmissionStatusUpdate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConsultationSubmissionStatusUpdateFromJSON(json: any): ConsultationSubmissionStatusUpdate {
    return ConsultationSubmissionStatusUpdateFromJSONTyped(json, false);
}

export function ConsultationSubmissionStatusUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationSubmissionStatusUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : ConsultationSubmissionStatusFromJSON(json['status']),
        'priority': !exists(json, 'priority') ? undefined : ConsultationSubmissionPriorityFromJSON(json['priority']),
        'conclusion': !exists(json, 'conclusion') ? undefined : json['conclusion'],
    };
}

export function ConsultationSubmissionStatusUpdateToJSON(value?: ConsultationSubmissionStatusUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ConsultationSubmissionStatusToJSON(value.status),
        'priority': ConsultationSubmissionPriorityToJSON(value.priority),
        'conclusion': value.conclusion,
    };
}

