import { getAccessToken } from "../helpers/auth";
import { CentresApi, Configuration, ResponseError } from "./utc-api";
import { UTC_API_URL } from "../config/app-config";

export const utcApiConfiguration = new Configuration({
  basePath: UTC_API_URL,
  accessToken: `Bearer ${getAccessToken()}`
});

export const handleApiError = async (e: any) => {
  if (e instanceof ResponseError) {
    if (e.response.status === 401) {
      // return alert("Authentication error - please reload");
    }
  }

  // alert(e.message ?? e);
  console.error(e);
};

export const makeRequest = async <T>(func: Promise<T>) => {
  try {
    return await func;
  } catch (e) {
    handleApiError(e);
    return null;
  }
};

export const centresApi = new CentresApi(utcApiConfiguration);
