/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationSubmissionStage } from './ConsultationSubmissionStage';
import {
    ConsultationSubmissionStageFromJSON,
    ConsultationSubmissionStageFromJSONTyped,
    ConsultationSubmissionStageToJSON,
} from './ConsultationSubmissionStage';

/**
 * 
 * @export
 * @interface ConsultationSubmissionBody
 */
export interface ConsultationSubmissionBody {
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionBody
     */
    title: string;
    /**
     * 
     * @type {Array<ConsultationSubmissionStage>}
     * @memberof ConsultationSubmissionBody
     */
    stages: Array<ConsultationSubmissionStage>;
}

/**
 * Check if a given object implements the ConsultationSubmissionBody interface.
 */
export function instanceOfConsultationSubmissionBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "stages" in value;

    return isInstance;
}

export function ConsultationSubmissionBodyFromJSON(json: any): ConsultationSubmissionBody {
    return ConsultationSubmissionBodyFromJSONTyped(json, false);
}

export function ConsultationSubmissionBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationSubmissionBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'stages': ((json['stages'] as Array<any>).map(ConsultationSubmissionStageFromJSON)),
    };
}

export function ConsultationSubmissionBodyToJSON(value?: ConsultationSubmissionBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'stages': ((value.stages as Array<any>).map(ConsultationSubmissionStageToJSON)),
    };
}

