/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface StaffMember
 */
export interface StaffMember {
    /**
     * 
     * @type {string}
     * @memberof StaffMember
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof StaffMember
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof StaffMember
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof StaffMember
     */
    sex?: string;
    /**
     * 
     * @type {Address}
     * @memberof StaffMember
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof StaffMember
     */
    email: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StaffMember
     */
    assignedCentres?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StaffMember
     */
    id: string;
}

/**
 * Check if a given object implements the StaffMember interface.
 */
export function instanceOfStaffMember(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function StaffMemberFromJSON(json: any): StaffMember {
    return StaffMemberFromJSONTyped(json, false);
}

export function StaffMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffMember {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'email': json['email'],
        'assignedCentres': !exists(json, 'assignedCentres') ? undefined : json['assignedCentres'],
        'id': json['id'],
    };
}

export function StaffMemberToJSON(value?: StaffMember | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'sex': value.sex,
        'address': AddressToJSON(value.address),
        'email': value.email,
        'assignedCentres': value.assignedCentres,
        'id': value.id,
    };
}

