/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ConsultationSubmissionPriority = {
    P1: 'P1',
    P2: 'P2',
    P3: 'P3',
    P4: 'P4',
    P5: 'P5'
} as const;
export type ConsultationSubmissionPriority = typeof ConsultationSubmissionPriority[keyof typeof ConsultationSubmissionPriority];


export function ConsultationSubmissionPriorityFromJSON(json: any): ConsultationSubmissionPriority {
    return ConsultationSubmissionPriorityFromJSONTyped(json, false);
}

export function ConsultationSubmissionPriorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationSubmissionPriority {
    return json as ConsultationSubmissionPriority;
}

export function ConsultationSubmissionPriorityToJSON(value?: ConsultationSubmissionPriority | null): any {
    return value as any;
}

