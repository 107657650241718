/* tslint:disable */
/* eslint-disable */
/**
 * UTC API
 * UTC API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsultationFormQuestionType } from './ConsultationFormQuestionType';
import {
    ConsultationFormQuestionTypeFromJSON,
    ConsultationFormQuestionTypeFromJSONTyped,
    ConsultationFormQuestionTypeToJSON,
} from './ConsultationFormQuestionType';

/**
 * 
 * @export
 * @interface ConsultationSubmissionStageQuestion
 */
export interface ConsultationSubmissionStageQuestion {
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionStageQuestion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionStageQuestion
     */
    question?: string;
    /**
     * 
     * @type {ConsultationFormQuestionType}
     * @memberof ConsultationSubmissionStageQuestion
     */
    type?: ConsultationFormQuestionType;
    /**
     * 
     * @type {string}
     * @memberof ConsultationSubmissionStageQuestion
     */
    answer?: string;
}

/**
 * Check if a given object implements the ConsultationSubmissionStageQuestion interface.
 */
export function instanceOfConsultationSubmissionStageQuestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ConsultationSubmissionStageQuestionFromJSON(json: any): ConsultationSubmissionStageQuestion {
    return ConsultationSubmissionStageQuestionFromJSONTyped(json, false);
}

export function ConsultationSubmissionStageQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsultationSubmissionStageQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'question': !exists(json, 'question') ? undefined : json['question'],
        'type': !exists(json, 'type') ? undefined : ConsultationFormQuestionTypeFromJSON(json['type']),
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
    };
}

export function ConsultationSubmissionStageQuestionToJSON(value?: ConsultationSubmissionStageQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'question': value.question,
        'type': ConsultationFormQuestionTypeToJSON(value.type),
        'answer': value.answer,
    };
}

